<template>
	<article
			:style="type === TAG_TYPE_ANIMALS ? {'background-image': `url(${require('../../assets/images/paw.png')})`} : ''"
			class="card"
	>
		<div class="card__header">
			<v-avatar v-if="img" class="card__avatar" size="56">
				<img
						:src="img"
						:alt="name"
				>
			</v-avatar>
			<v-avatar v-if="!img" color="grey lighten-2" class="card__avatar" size="56">
				{{ name ? name.charAt(0) : null }}
			</v-avatar>

			<h2 class="card__name">{{ name ? name : token }}</h2>
		</div>
		<div class="card__actions">
			<div class="card__action">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn fab v-bind="attrs" v-on="on" :to="paths().dynamic.tag(id)">
							<v-icon color="success">
								mdi-pencil
							</v-icon>
						</v-btn>
					</template>
					<span>Edit</span>
				</v-tooltip>
			</div>
			<div class="card__action">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn fab v-bind="attrs" v-on="on" :to="paths().dynamic.tagPreview(token)">
							<v-icon color="grey">mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Preview</span>
				</v-tooltip>
			</div>
			<CardDelete :id="id" />
		</div>
	</article>
</template>

<script>
	import CardDelete from "./CardDelete";
	import { mixinPaths } from "../../mixins/mixinPaths";
	import { mixinTagTypes } from "../../mixins/mixinTagTypes";


	export default {
		name: "Card",
		components: { CardDelete },
		mixins: [
			mixinPaths,
			mixinTagTypes
		],
		props: [
			'id',
			'token',
			'img',
			'name',
			'type',
		],
		data() {
			return {
				dialog: false,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.card {
		display: flex;
		align-items: center;
		box-shadow: 0 0 8px 1px #e8e8e8;
		background-color: $c-white;
		padding: 2rem;
		border-radius: 4px;
		margin-bottom: 2rem;
		background-size: contain;

		@include breakpoint(phone) {
			flex-direction: column;
		}

		@include breakpoint(overPhone) {
			justify-content: space-between;
		}

		&:last-of-type {
			margin-right: 0;
		}

		&__header {
			position: relative;
			z-index: 2;
			display: flex;
			align-items: center;
			@include breakpoint(phone) {
				width: 100%;
				margin-bottom: 2rem;
			}
		}

		&__avatar {
			margin-right: 2rem;
			font-size: 1.6rem;
			text-transform: uppercase;
		}

		&__name {
			font-size: 2rem;
		}

		&__actions {
			position: relative;
			z-index: 2;
			display: flex;
			align-items: center;

			@include breakpoint(phone) {
				width: 100%;
				justify-content: space-between;
			}
		}

		&__action {
			margin-right: 1rem;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
</style>
