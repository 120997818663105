<template>
	<v-dialog
			class="popup"
			v-model="dialog"
			width="400"
			@click:outside="onDialogClose"
	>
		<template #activator="{ on: dialog }">
			<div class="card__action">
				<v-tooltip bottom>
					<template #activator="{ on: tooltip }">
						<v-btn fab v-on="{ ...dialog, ...tooltip }">
							<v-icon color="error">
								mdi-delete
							</v-icon>
						</v-btn>
					</template>
					<span>Delete</span>
				</v-tooltip>
			</div>
		</template>

		<v-card>
			<v-card-title class="headline grey lighten-2 mb-4">
				<strong>Are you sure you want to delete this {{ BRAND_NAME }}?</strong>
			</v-card-title>

			<div class="popup__content">
				<p class="popup__text">
					All data from the {{ BRAND_NAME }} will be lost.
				</p>

				<p class="popup__text">
					After deleting, the {{ BRAND_NAME }} can be registered again.
				</p>
			</div>

			<v-divider></v-divider>

			<v-card-actions class="popup__actions">
				<v-btn
						type="button"
						@click="onDialogClose"
				>
					Cancel
				</v-btn>
				<v-btn
						type="submit"
						@click.prevent="onSubmit"
						color="error"
				>
					Yes
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mapActions } from "vuex";
	import { mixinBrandName } from "../../mixins/mixinBrandName";


	export default {
		name: "CardDelete",
		mixins: [mixinBrandName],
		props: [
			'id',
		],
		data() {
			return {
				dialog: false,
			};
		},
		methods: {
			...mapActions( [
				'requestTagDelete',
				'requestGetTags',
			] ),
			onDialogClose: function () {
				this.dialog = false;
			},
			onSubmit: function () {
				this.requestTagDelete( this.id );
				this.onDialogClose();
			},
		}
	};
</script>
