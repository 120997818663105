<template>
	<section class="card-list l--content">
		<Card
				v-for="({ id, token, type_name, data }) in data"
				:key="id"
				:id="id"
				:img="data && data.image_url ? data.image_url : ''"
				:name="nameHandler(type_name, data)"
				:token="token"
				:type="type_name"
		/>
		<p
				class="card-list__empty"
				v-if="!data || !data.length"
		>
			Your {{ BRAND_NAME }} list is empty
		</p>
	</section>
</template>

<script>
	import Card from "./Card";
	import { mixinTagTypes } from "../../mixins/mixinTagTypes";
	import { mixinBrandName } from "../../mixins/mixinBrandName";


	export default {
		name: "CardList",
		components: { Card },
		props: [
			'data'
		],
		mixins: [
			mixinTagTypes,
			mixinBrandName,
		],
		methods: {
			nameHandler( type, data ) {
				if ( !data ) return '';

				const { first_name, last_name, animal_name } = data;

				if ( type === this.TAG_TYPE_ANIMALS ) return animal_name
					? animal_name
					: '';

				if ( !first_name && !last_name ) {
					return '';
				}

				return `${ first_name || '' }${ first_name
					? ` ${ last_name || '' }`
					: last_name || '' }`;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card-list {

		&__empty {
			text-align: center;
			font-size: 1.6rem;
			margin-top: 4rem;
		}
	}
</style>
