import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import CustomIconTikTok from "../components/CustomIcons/CustomIconTikTok";
import CustomIconBooksy from "../components/CustomIcons/CustomIconBooksy";
import CustomIconTripadvisor from "../components/CustomIcons/CustomIconTripadvisor";

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		values: {
			tiktok: { // name of our custom icon
				component: CustomIconTikTok, // our custom component
			},
			booksy: { // name of our custom icon
				component: CustomIconBooksy, // our custom component
			},
			tripadvisor: { // name of our custom icon
				component: CustomIconTripadvisor, // our custom component
			},
		},
	},
})
