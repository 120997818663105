export const mixinValidationRules = {
  data() {
    return {
      validationRules: {
        required: v => !!v || 'Field is required',
        email: v => {
          if ( !v ) return true;

          return (/.+@.+\..+/.test(v) || 'E-mail must be valid');
        },
        phone: v => {
          if ( !v ) return true;

          return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(v) || 'Phone must be valid';
        },
        username: {
          start: v => !/[^A-Za-z]+/.test(v.charAt(0)) || 'Username must start with letter',
          characters: v => !/[^A-Za-z0-9]+/.test(v) || 'Only letters and numbers are allowed',
          min: v => v?.length >= 3 || 'At least 3 characters',
        },
        password: {
          lowercase: v => /[a-z]/.test(v) || 'At least one lower case letter',
          uppercase: v => /[A-Z]/.test(v) || 'At least one upper case letter',
          digit: v => !/^([^0-9]*)$/.test(v) || 'At least one digit',
          min: v => v?.length >= 8 || 'At least 8 characters',
        },
        protocol: v => {
          if ( !v ) return true;

          return /(^http[s]?:\/{2})/.test( v ) || 'Link must start with http:// or https://';
        },
      },
    };
  },
};
