import paths from "../constants/paths";
import store from '../store';

export const ifNotAuthenticated = ( to, from, next ) => {
	if (!store.getters.isAuthenticated) {
		next();
		return;
	}
	next( paths.home );
};

export const ifAuthenticated = ( to, from, next ) => {
	if ( store.getters.isAuthenticated ) {
		next();
		return;
	}
	next( paths.login );
};
