<template>
	<Page class="home">
		<Title title="Dashboard" />
		<header class="cards-header l--content">
			<h3 class="cards-header__title">{{ BRAND_NAME }} list</h3>
			<CardAdd />
		</header>

		<Loader v-if="tags.getTags.isLoading" />
		<Error v-if="tags.getTags.error" />
		<CardList v-if="tags.getTags.data" :data="tags.getTags.data" />

	</Page>
</template>

<script>

	import Page from "../components/layouts/LayoutPage";
	import Title from "../components/common/Title";
	import CardList from "../components/Card/CardList";
	import {
		mapActions,
		mapState,
	} from "vuex";
	import Loader from "../components/common/Loader";
	import Error from "../components/common/Error";
	import CardAdd from "../components/Card/CardAdd";
	import { mixinBrandName } from "../mixins/mixinBrandName";


	export default {
		name: 'Home',
		components: { Error, Loader, CardList, Title, Page, CardAdd },
		mixins: [
			mixinBrandName
		],
		data() {
			return {};
		},
		computed: {
			...mapState( [
				'tags'
			] ),
		},
		methods: {
			...mapActions( [
				'requestGetTags',
			] ),
		},
		created() {
			this.requestGetTags();
		},
	};
</script>

<style lang="scss" scoped>
	.cards-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2rem;

		&__title {
			font-size: 2.2rem;
		}
	}
</style>
