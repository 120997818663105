import Vue from 'vue';
import Vuex from 'vuex';
import { createModule } from 'vuex-toast'
import createLogger from "vuex/dist/logger";
import auth from './modules/auth';
import user from './modules/user';
import tags from './modules/tags';
import tag from './modules/tag';
import 'vuex-toast/dist/vuex-toast.css'
import { RESET_STATE } from "./types";


Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const initialState = {
  auth: { ...auth.state },
  user: { ...user.state },
  tags: { ...tags.state },
  tag: { ...tag.state },
};

export default new Vuex.Store({
  plugins: debug ? [createLogger()] : [],
  strict: debug,
  modules: {
    auth,
    user,
    tags,
    tag,
    toast: createModule({
      dismissInterval: 4000
    })
  },
  mutations: {
    [RESET_STATE]: state => {
      Object.keys(state).forEach(key => {
        Object.assign(state[key], initialState[key])
      });
      state.auth.login.token = null;
    }
  }
})
