import {
	TAG_ADD_ERROR,
	TAG_ADD_RESET,
	TAG_ADD_START,
	TAG_ADD_SUCCESS,
	GET_TAGS_ERROR,
	GET_TAGS_START,
	GET_TAGS_SUCCESS,
} from "./types";
import service from "../../../../service";
import {
	API_TAG_ADD,
	API_GET_TAGS,
	API_TAG_DELETE
} from "../../../../endpoints";
import { ADD_TOAST_MESSAGE } from "vuex-toast";
import router from "../../../router";
import paths from "../../../constants/paths";

const state = {
	getTags: {
		isLoading: false,
		error: false,
		data: null,
	},
	addTag: {
		isLoading: false,
		error: '',
		data: null,
	},
};

const getters = {};

const mutations = {
	// GET TAGS
	[GET_TAGS_START]: state => {
		state.getTags.data = null;
		state.getTags.error = false;
		state.getTags.isLoading = true;
	},
	[GET_TAGS_SUCCESS]: (state, data) => {
		state.getTags.error = false;
		state.getTags.isLoading = false;
		state.getTags.data = data;
	},
	[GET_TAGS_ERROR]: (state) => {
		state.getTags.data = null;
		state.getTags.isLoading = false;
		state.getTags.error = true;
	},
	// END GET TAGS

	// GET TAGS
	[TAG_ADD_START]: state => {
		state.addTag.data = null;
		state.addTag.error = '';
		state.addTag.isLoading = true;
	},
	[TAG_ADD_SUCCESS]: (state, data) => {
		state.addTag.error = '';
		state.addTag.isLoading = false;
		state.addTag.data = data;
	},
	[TAG_ADD_ERROR]: (state, data) => {
		state.addTag.data = null;
		state.addTag.isLoading = false;
		state.addTag.error = data;
	},
	[TAG_ADD_RESET]: (state) => {
		state.addTag.data = null;
		state.addTag.isLoading = false;
		state.addTag.error = '';
	},
	// END GET TAGS
};

const actions = {
	// GET TAGS
	async requestGetTags({ commit }) {
		commit(GET_TAGS_START);

		try {
			const { data } = await service.get(API_GET_TAGS);
			commit(GET_TAGS_SUCCESS, data?.tokens);
		} catch (e) {
			console.log( e, 'requestGetTags' );
			commit(GET_TAGS_ERROR);
		}
	},

	// ADD TAG
	async requestTagAdd({ commit, dispatch }, dataToSend) {
		commit(TAG_ADD_START);

		try {
			const { data: addData } = await service.post(API_TAG_ADD, dataToSend);
			commit(TAG_ADD_SUCCESS, addData);
			dispatch(ADD_TOAST_MESSAGE, { text: `TapMe5 has been successfully added`, type: 'success'});
			if (addData.token_id) {
				await router.push( paths.dynamic.tag( addData.token_id ) );
			} else {
				dispatch('requestGetTags');
			}
		} catch (e) {
			console.log( e, 'requestTagAdd' );
			const error = e.response?.data?.msg || 'Something went wrong';
			dispatch(ADD_TOAST_MESSAGE, { text: error, type: 'danger', dismissAfter: 10000});
			commit(TAG_ADD_ERROR, error);
		}
	},
	async requestResetTagAddState({ commit }) {
		commit(TAG_ADD_RESET);
	},
	// END ADD TAG

	// DELETE TAG
	async requestTagDelete({ dispatch }, token) {
		try {
			await service.post(API_TAG_DELETE, { token_id: token });
			dispatch(ADD_TOAST_MESSAGE, { text: `TapMe5 has been successfully deleted`, type: 'success'});
			dispatch('requestGetTags');
		} catch (e) {
			console.log( e, 'requestTagAdd' );
			const error = e.response?.data?.msg || 'Something went wrong';
			dispatch(ADD_TOAST_MESSAGE, { text: error, type: 'danger'});
		}
	},
	// END DELETE TAG
};

export default {
	state,
	getters,
	mutations,
	actions,
}
