<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024" style="background-color: #44cfa7">
		<path fill="#000" d="M439.2 242c-3.2 1-6.6 3-7.4 4.4-1 1.4-1.8 68.4-1.8 148.8v146.2l5.4 5.4c7 7 35.8 26.2 45 30.2 6.6 2.6 7.6 2.6 11.8-0.8 4-3.2 4.4-5.8 5.8-33.4 1.2-28.2 1.6-30.6 7.8-43.8 8.8-19 20.6-33.6 35.6-44.2 40.8-29.2 94.4-23.8 128.8 12.8 7 7.4 15.2 18.2 18.4 23.8 17.4 32.4 18 85.4 1.2 116.8-11.8 22-41 44.2-69.6 52.6-18 5.2-38.8 6-61.2 2.2-48.6-8.4-83-27.2-172.4-94-44-32.8-62.2-43.8-86.6-51.4-12.8-4-19.2-4.6-44-4.6-33.8 0-52.6 4-78.2 17-34.2 17-56 39.4-73.2 74.6-8 16.4-8.2 25-0.6 28.8 6.8 3.4 57.2 3.4 63.8 0 2.8-1.2 7.6-7 10.8-12.4 19.4-33.4 52.4-48.6 87.6-40.2 27 6.2 45.8 17.2 115.2 67.8 47 34 67.2 47 91.6 58.6 40.8 19.4 71.4 25.8 116 24.2 36.6-1.2 55.8-5.6 84-19.4 35-17.2 60.6-42.4 78-77 13-26.2 17.6-47.6 17.6-82 0.2-60-17.6-103-58.4-141.4-12.6-12-20.6-17.4-36.6-25.2-26.2-12.8-40.8-16.4-70.6-17.8-30.2-1.4-48.8 2.8-78 17.2-11 5.6-21.4 10.2-23 10.6-2.6 0.6-3.2-8.8-4-71.4-1-74.2-1.6-78.8-9.4-82-5.6-2.4-43-3.2-49.4-1z"></path>
	</svg>
</template>

<script>
	export default {
		name: "CustomIconBooksy"
	};
</script>
