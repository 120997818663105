// PRIVATE
export const API_REGISTER = "/api/auth/register";
export const API_VERIFY_EMAIL = "/api/auth/email/verify";
export const API_LOGIN = "/api/auth/login";
export const API_LOGOUT = "/api/auth/logout";
export const API_USER_ACCOUNT_DELETE = "/api/auth/delete-user";
export const API_PASSWORD_RESET_LINK = "/api/auth/password/email";
export const API_PASSWORD_RESET = "/api/auth/password/reset";
export const API_PASSWORD_CHANGE = "/api/auth/password/update";
export const API_GET_USER_DETAILS = "/api/auth/get-user-details";
export const API_GET_TAGS = "/api/auth/tokens/user-tokens";
export const API_TAG_ADD = "/api/auth/tokens/assign";
export const API_TAG_DELETE = "/api/auth/tokens/delete-token";
export const API_TAG_SINGLE_GET_PRIVATE = "/api/auth/tokens/get-token-details";
export const API_TAG_SINGLE_UPDATE = "/api/auth/tokens/update-details";
export const API_AVATAR_UPLOAD = "/api/auth/tokens/upload-image";
export const API_AVATAR_DELETE = "/api/auth/tokens/delete-token-image";

// PUBLIC
export const API_TAG_SINGLE_GET_PUBLIC = "/api/token-details";
export const API_TAG_FILE = "/api/token/get-contact";
