<template>
	<h1 class="app-title">{{ title }}</h1>
</template>

<script>
	export default {
		name: "Title",
		props: [
			'title',
		],
	};
</script>

<style lang="scss" scoped>
.app-title {
	font-size: 3rem;
	text-align: center;
	color: $c-black;
	margin-bottom: 6rem;

	@include breakpoint(overPhone) {
		font-size: 3.6rem;
	}
}
</style>
