import axios from "axios";
import { LOCAL_STORAGE_USER_TOKEN } from "./src/constants/localStorage";

const service = axios.create( {
	baseURL: process.env.VUE_APP_API_URL
} );

// Request interceptor for API calls
service.interceptors.request.use(
	async config => {

		const token = localStorage.getItem( LOCAL_STORAGE_USER_TOKEN );

		if (token) {
			config.headers = {
				'Authorization': `Bearer ${token}`,
			};
		}
		return config;
	},
error => {
	Promise.reject(error)
});

export default service;
