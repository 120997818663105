import {
	GET_USER_DETAILS_ERROR,
	GET_USER_DETAILS_START,
	GET_USER_DETAILS_SUCCESS,
} from "./types";
import service from "../../../../service";
import { API_GET_USER_DETAILS } from "../../../../endpoints";
import { LOCAL_STORAGE_USER_TOKEN } from "../../../constants/localStorage";
import { RESET_STATE } from "../../types";
import router from "../../../router";
import paths from "../../../constants/paths";

const state = {
	isLoading: false,
	error: false,
	data: null,
};

const getters = {};

const mutations = {
	[GET_USER_DETAILS_START]: state => {
		state.data = null;
		state.error = false;
		state.isLoading = true;
	},
	[GET_USER_DETAILS_SUCCESS]: (state, data) => {
		state.error = false;
		state.isLoading = false;
		state.data = data;
	},
	[GET_USER_DETAILS_ERROR]: (state) => {
		state.data = null;
		state.isLoading = false;
		state.error = true;
	},
};

const actions = {
	// GET USER DETAILS
	async requestGetUserDetails({ commit }) {
		commit(GET_USER_DETAILS_START);

		try {
			const { data: userData } = await service.get(API_GET_USER_DETAILS);
			commit(GET_USER_DETAILS_SUCCESS, userData);
		} catch (e) {
			console.log( e, 'requestGetUserDetails' );
			await localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN);
			await commit(RESET_STATE);
			await router.push( paths.login );
			commit(GET_USER_DETAILS_ERROR);
		}
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
}
