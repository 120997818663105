import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import Home from '../views/Home.vue';
import {
	ifAuthenticated,
	ifNotAuthenticated
} from "../utils/auth";
import paths from "../constants/paths";

Vue.use( VueRouter );
Vue.use( VueMeta );

const routes = [
	{
		path: paths.home,
		name: 'Home',
		component: Home,
		beforeEnter: ifAuthenticated,
	},
	{
		path: paths.tag,
		name: 'Tag Edit',
		component: () => import('../views/Tag/TagEdit.vue'),
		beforeEnter: ifAuthenticated,
	},
	{
		path: paths.tagPreview,
		name: 'Tag Preview',
		component: () => import('../views/Tag/TagPreview.vue'),
	},
	{
		path: paths.myAccount,
		name: 'My Account',
		component: () => import('../views/MyAccount/MyAccount.vue'),
		beforeEnter: ifAuthenticated,
	},
	{
		path: paths.login,
		name: 'Login',
		component: () => import('../views/Login.vue'),
		beforeEnter: ifNotAuthenticated,
	},
	{
		path: paths.register,
		name: 'Register',
		component: () => import('../views/Register.vue'),
		beforeEnter: ifNotAuthenticated,
	},
	{
		path: paths.registerConfirm,
		name: 'Register Confirm',
		component: () => import('../views/RegisterConfirm.vue'),
		beforeEnter: ifNotAuthenticated,
	},
	{
		path: paths.verifyEmail,
		name: 'Verify Email',
		component: () => import('../views/VerifyEmail.vue'),
		beforeEnter: ifNotAuthenticated,
	},
	{
		path: paths.passwordReset,
		name: 'Password Reset',
		component: () => import('../views/PasswordReset.vue'),
		beforeEnter: ifNotAuthenticated,
	},
	{
		path: paths.passwordResetLink,
		name: 'Password Reset Link',
		component: () => import('../views/PasswordResetLink.vue'),
		beforeEnter: ifNotAuthenticated,
	},
	{
		path: paths.passwordResetLinkConfirm,
		name: 'Password Reset Confirm',
		component: () => import('../views/PasswordResetConfirm.vue'),
		beforeEnter: ifNotAuthenticated,
	},
	{
		path: '*',
		name: 'Not Found',
		component: () => import('../views/NotFound.vue'),
	},
];

const router = new VueRouter( {
	mode: 'history',
	base: process.env.BASE_URL,
	routes
} );

export default router;
