<template>
	<footer class="footer">
		<div class="footer__body l">
			<p class="copy">© 2020 - TapMe5 - All rights reserved</p>
		</div>
	</footer>
</template>

<script>
	export default {
		name: "Footer",
	};
</script>

<style lang="scss" scoped>
	.footer {
		padding: 2.5rem 0;
		border-top: 1px solid $c-brand-primary;
		margin-top: auto;
	}
	.copy {
		font-size: 1.4rem;
		text-align: center;
		color: $c-black;
	}
</style>
