<template>
	<section class="page l">
		<slot />
	</section>
</template>

<script>
	export default {
		name: "LayoutPage",
	};
</script>

<style lang="scss">
	.page {
		height: 100%;
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
</style>
