<template>
	<v-app>
		<Header />
		<v-main>
			<router-view />
			<toast position="sw" />
		</v-main>
		<Footer />
	</v-app>
</template>

<script>
	import Header from "./components/layouts/Header";
	import Footer from "./components/layouts/Footer";
	import { Toast } from 'vuex-toast';
	import {
		SEO_DESCRIPTION,
		SEO_TITLE
	} from "./constants/seo";
	import seoImage from './assets/images/tapme5-seo-image.png';


	export default {
		name: 'App',
		components: { Footer, Header, Toast },
		created() {
			if (
				this.$store.state.auth.login.token
				&& !this.$store.state.user.data
			) {
				this.$store.dispatch( "requestGetUserDetails" );
			}
		},
		metaInfo() {
			const title = SEO_TITLE;

			return {
				title: title,
				meta: [
					{
						vmid: 'description',
						name: 'description',
						content: SEO_DESCRIPTION,
					},

					{
						vmid: 'og:title',
						name: 'og:title',
						content: title,
					},
					{
						vmid: 'og:description',
						name: 'og:description',
						content: SEO_DESCRIPTION,
					},
					{
						vmid: 'og:url',
						name: 'og:url',
						content: `${ process.env.VUE_APP_APP_URL }${ this.$route.fullPath }`,
					},
					{
						vmid: 'og:image',
						name: 'og:image',
						content: seoImage,
					},
				],
				titleTemplate: '%s | TapMe5'
			};
		}
	};
</script>
<style lang="scss">
	@import "assets/scss/global-style/global-style";
</style>

