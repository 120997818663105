<template>
	<div class="loader">
		<v-progress-circular
				indeterminate
		/>
	</div>
</template>

<script>
	export default {
		name: "Loader"
	};
</script>

<style lang="scss">
	.loader {
		display: flex;
		justify-content: center;
		color: $c-brand-primary;
		caret-color: $c-brand-primary;
	}
</style>
