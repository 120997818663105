<template>
	<header class="header">
		<div class="header__body l">
			<v-menu
					transition="slide-x-transition"
					bottom
					right
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
							v-bind="attrs"
							v-on="on"
							fab
					>
						<v-icon
								class="menu"
								large
						>
							mdi-menu
						</v-icon>
					</v-btn>
				</template>

				<v-list class="menu-list">
					<v-list-item
							v-for="item in isAuthenticated ? menuAuth : menuNotAuth"
							:key="`${item.title}_${item.href}`"
					>
						<v-list-item-title>
							<router-link
									active-class="active"
									class="menu-link"
									:to="item.href"
									:exact="item.exact"
							>
								{{ item.title }}
							</router-link>
						</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="isAuthenticated">
						<div class="menu-link" @click="requestLogout">Logout</div>
					</v-list-item>
				</v-list>
			</v-menu>
			<router-link :to="paths().home">
				<img
						class="logo"
						src="../../assets/images/logo/tapMe5-logo-primary.svg"
						alt="TapMe5"
				/>
			</router-link>
			<v-btn :href="shopLink" target="_blank" fab>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-icon
								class="shop"
								large
								v-bind="attrs"
								v-on="on"
						>
							mdi-cart
						</v-icon>
					</template>
					<span>Shop now</span>
				</v-tooltip>
			</v-btn>
		</div>
	</header>
</template>

<script>
	import { mixinPaths } from "../../mixins/mixinPaths";
	import { mixinAuth } from "../../mixins/mixinAuth";
	import { mapActions } from "vuex";


	export default {
		name: "Header",
			mixins: [
				mixinPaths,
				mixinAuth,
			],
		data: function () {
			return {
				shopLink: process.env.VUE_APP_SHOP_URL,
				menuAuth: [
					{
						title: 'Dashboard',
						href: this.paths().home,
						exact: true
					},
					{
						title: 'My Account',
						href: this.paths().myAccount,
					},
				],
				menuNotAuth: [
					{
						title: `Login`,
						href: this.paths().login,
					},
					{
						title: 'Register',
						href: this.paths().register,
					},
				],
			};
		},
		methods: {
			...mapActions( [
				'requestLogout',
			] ),
		}
	};
</script>

<style lang="scss" scoped>
	.header {
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 10;
		background-color: $c-gray-primary;
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

		&__body {
			height: 8rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.logo {
		width: 12rem;

		@include breakpoint(overPhone) {
			width: 14rem;
		}
	}

	.menu,
	.shop, {
		color: $c-brand-primary !important;
	}

	.menu-link {
		font-size: 1.6rem;
		color: $c-black;
		transition: $transition;
		cursor: pointer;

		&:hover,
		&.active {
			color: $c-brand-primary;
		}
	}

	.menu-list {
		min-width: 15rem;
	}
</style>
