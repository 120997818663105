<template>
	<div class="app-error">
		<p class="app-error__message">
			{{ message ? message : 'Something went wrong' }}
		</p>

		<slot />
	</div>
</template>

<script>
	export default {
		name: "Error",
		props: ['message'],
	};
</script>

<style lang="scss">
	.app-error {
		&__message {
			font-size: 1.6rem;
			color: $c-error;
			margin-bottom: 2rem;
			text-align: center;
		}
	}
</style>
