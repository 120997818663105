import service from "../../../../service";
import {
	TAG_SINGLE_GET_ERROR,
	TAG_SINGLE_GET_RESET,
	TAG_SINGLE_GET_START,
	TAG_SINGLE_GET_SUCCESS,
	TAG_SINGLE_UPDATE_START,
	TAG_SINGLE_UPDATE_FINISH,
} from "./types";
import {
	API_TAG_SINGLE_GET_PRIVATE,
	API_TAG_SINGLE_GET_PUBLIC,
	API_TAG_SINGLE_UPDATE
} from "../../../../endpoints";
import { ADD_TOAST_MESSAGE } from "vuex-toast";
import router from '../../../router';

const state = {
	singleDataGet: {
		isLoading: false,
		error: false,
		data: null,
	},
	singleDataUpdate: {
		isLoading: false,
	},
};

const getters = {};

const mutations = {
	// GET SINGLE TAG
	[TAG_SINGLE_GET_START]: state => {
		state.singleDataGet.data = null;
		state.singleDataGet.error = false;
		state.singleDataGet.isLoading = true;
	},
	[TAG_SINGLE_GET_SUCCESS]: (state, data) => {
		state.singleDataGet.error = false;
		state.singleDataGet.isLoading = false;
		state.singleDataGet.data = data;
	},
	[TAG_SINGLE_GET_ERROR]: state => {
		state.singleDataGet.data = null;
		state.singleDataGet.isLoading = false;
		state.singleDataGet.error = true;
	},
	[TAG_SINGLE_GET_RESET]: (state) => {
		state.singleDataGet.data = null;
		state.singleDataGet.isLoading = false;
		state.singleDataGet.error = false;
	},
	// END GET SINGLE TAG

	// UPDATE SINGLE TAG
	[TAG_SINGLE_UPDATE_START]: state => {
		state.singleDataUpdate.isLoading = true;
	},
	[TAG_SINGLE_UPDATE_FINISH]: state => {
		state.singleDataUpdate.isLoading = false;
	},
	// END UPDATE SINGLE TAG
};

const actions = {
	// GET SINGLE TAG
	async requestGetSingleTag({ commit, dispatch }, tagId) {
		commit(TAG_SINGLE_GET_START);

		try {
			const { data } = await service.post(API_TAG_SINGLE_GET_PRIVATE, {
				token_id: tagId
			});
			commit(TAG_SINGLE_GET_SUCCESS, {
				...data.networks,
				isOwner: data.is_owner,
			});
		} catch (e) {
			console.log( e, 'requestGetSingleTag' );
			dispatch(ADD_TOAST_MESSAGE, { text: 'Something went wrong', type: 'danger'});
			commit(TAG_SINGLE_GET_ERROR);
		}
	},

	// RESET GET SINGLE TAG STATE
	async requestResetGetSingleTag({ commit }) {
		commit(TAG_SINGLE_GET_RESET);
	},

	// UPDATE SINGLE TAG
	async requestUpdateSingleTag({ commit, dispatch }, { data, redirectOnSuccess }) {
		commit(TAG_SINGLE_UPDATE_START);
		try {
			await service.post(API_TAG_SINGLE_UPDATE, data);
			dispatch(ADD_TOAST_MESSAGE, { text: 'TapMe5 has been successfully updated', type: 'success'});
			if (redirectOnSuccess) {
				await router.push(redirectOnSuccess)
			} else {
				dispatch('requestGetSingleTag', data.token_id);
			}
		} catch (e) {
			console.log( e, 'requestUpdateSingleTag' );
			dispatch(ADD_TOAST_MESSAGE, { text: 'Something went wrong', type: 'danger'});
		} finally {
			commit(TAG_SINGLE_UPDATE_FINISH);
		}
	},

	// GET SINGLE TAG PUBLIC
	async requestGetSingleTagPublic({ commit, dispatch }, passcode) {
		commit(TAG_SINGLE_GET_START);

		try {
			const { data } = await service.post(API_TAG_SINGLE_GET_PUBLIC, {
				code: passcode
			});
			commit(TAG_SINGLE_GET_SUCCESS, {
				...data.networks,
				isOwner: data.is_owner,
			});
		} catch (e) {
			console.log( e, 'requestGetSingleTagPublic' );
			dispatch(ADD_TOAST_MESSAGE, { text: 'Something went wrong', type: 'danger'});
			commit(TAG_SINGLE_GET_ERROR);
		}
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
}
