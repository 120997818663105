<template>
	<v-dialog
			class="popup"
			v-model="dialog"
			width="400"
			@click:outside="onDialogClose"
			:persistent="tags.addTag.isLoading"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
					x-large
					elevation="8"
					class="btn-primary"
					v-bind="attrs"
					v-on="on"
			>
				<v-icon left>
					mdi-plus
				</v-icon>
				Add new
			</v-btn>
		</template>

		<v-card>
			<v-card-title class="headline grey lighten-2 mb-4">
				<strong>Add new {{ BRAND_NAME }} tag/pet tag</strong>
			</v-card-title>

			<v-form
					ref="form"
					v-model="valid"
					lazy-validation
					class="popup__form"
			>
				<div class="popup__content">
					<v-text-field
							v-model="passcode"
							label="Enter the Passcode"
							:rules="[
                          validationRules.required,
                        ]"
							required
							color="none"
							@keydown.space.prevent
					></v-text-field>
					<v-text-field
							v-model="pin"
							label="Enter the PIN"
							:rules="[
                          validationRules.required,
                        ]"
							required
							color="none"
							@keydown.space.prevent
					></v-text-field>
				</div>

				<v-divider></v-divider>

				<v-card-actions class="popup__actions">
					<v-btn
							type="button"
							@click="onDialogClose"
					>
						Cancel
					</v-btn>
					<v-btn
							type="submit"
							@click.prevent="onSubmit"
							color="success"
							:disabled="tags.addTag.isLoading"
							:loading="tags.addTag.isLoading"
					>
						Add
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mixinValidationRules } from "../../mixins/mixinValidationRules";
	import {
		mapActions,
		mapState
	} from "vuex";
	import { mixinBrandName } from "../../mixins/mixinBrandName";


	export default {
		name: "CardAdd",
		mixins: [
			mixinValidationRules,
			mixinBrandName
		],
		data() {
			return {
				dialog: false,
				valid: false,
				passcode: '',
				pin: '',
			};
		},
		computed: {
			...mapState( [
				'tags'
			] ),
		},
		methods: {
			...mapActions( [
				'requestTagAdd',
				'requestResetTagAddState',
			] ),
			onDialogClose: function () {
				this.dialog = false;
				this.$refs.form.reset();
				this.requestResetTagAddState();
			},
			onSubmit: function () {
				if ( this.$refs.form.validate() ) {
					this.requestTagAdd( {
						code: this.passcode,
						pin: this.pin,
					} );
				}
			},
		}
	};
</script>
