<template>
	<svg style="background-color: #4ae0a1" height="682pt" viewBox="-21 -134 682.66669 682" width="682pt" xmlns="http://www.w3.org/2000/svg">
		<path d="m168.8125 138.601562c-49.015625 0-88.894531 39.875-88.894531 88.894532 0 49.019531 39.878906 88.902344 88.894531 88.902344 49.019531 0 88.898438-39.882813 88.898438-88.902344 0-49.019532-39.878907-88.894532-88.898438-88.894532zm0 108.488282c-10.804688 0-19.59375-8.789063-19.59375-19.59375 0-10.800782 8.789062-19.59375 19.59375-19.59375s19.59375 8.792968 19.59375 19.59375c0 10.804687-8.789062 19.59375-19.59375 19.59375zm0 0"/>
		<path d="m469.066406 138.601562c-49.019531 0-88.902344 39.875-88.902344 88.894532 0 49.019531 39.882813 88.902344 88.902344 88.902344 49.015625 0 88.898438-39.882813 88.898438-88.902344 0-49.019532-39.878906-88.894532-88.898438-88.894532zm.886719 108.488282c-10.808594 0-19.597656-8.789063-19.597656-19.59375 0-10.800782 8.789062-19.59375 19.597656-19.59375 10.804687 0 19.59375 8.792968 19.59375 19.59375 0 10.804687-8.789063 19.59375-19.59375 19.59375zm0 0"/>
		<path d="m640 58.683594h-143.117188c-42.578124-41.359375-108.367187-65.851563-177.941406-65.851563-66.738281 0-134.050781 25.078125-177.867187 65.851563h-139.894531l38.121093 60.660156c-24.515625 29.308594-39.300781 67.039062-39.300781 108.152344 0 93.085937 75.734375 168.8125 168.8125 168.8125 48.121094 0 91.597656-20.234375 122.375-52.644532l27.753906 64.046876 27.746094-64.046876c30.78125 32.410157 74.257812 52.644532 122.378906 52.644532 93.082032 0 168.816406-75.726563 168.816406-168.8125 0-40.058594-14.039062-76.894532-37.4375-105.875zm-471.1875 300.253906c-72.476562 0-131.433594-58.964844-131.433594-131.441406 0-72.476563 58.957032-131.433594 131.433594-131.433594s131.4375 58.957031 131.4375 131.433594c0 72.476562-58.960938 131.441406-131.4375 131.441406zm431.691406-131.441406c0 72.476562-58.960937 131.441406-131.4375 131.441406-72.472656 0-131.441406-58.960938-131.441406-131.441406 0-72.46875 58.96875-131.433594 131.441406-131.433594 72.476563 0 131.4375 58.964844 131.4375 131.433594zm0 0"/>
	</svg>
</template>

<script>
	export default {
		name: "CustomIconTripadvisor"
	};
</script>
