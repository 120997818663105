export const LOGIN_START = "🎬 LOGIN_START";
export const LOGIN_ERROR = "🚫 LOGIN_ERROR";
export const LOGIN_SUCCESS = "✅ LOGIN_SUCCESS";

export const REGISTER_START = "🎬 REGISTER_START";
export const REGISTER_ERROR = "🚫 REGISTER_ERROR";
export const REGISTER_SUCCESS = "✅ REGISTER_SUCCESS";

export const VERIFY_EMAIL_START = "🎬 VERIFY_EMAIL_START";
export const VERIFY_EMAIL_ERROR = "🚫 VERIFY_EMAIL_ERROR";
export const VERIFY_EMAIL_SUCCESS = "✅ VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_RESET = "🗑 VERIFY_EMAIL_RESET";

export const PASSWORD_RESET_LINK_START = "🎬 PASSWORD_RESET_LINK_START";
export const PASSWORD_RESET_LINK_ERROR = "🚫 PASSWORD_RESET_LINK_ERROR";
export const PASSWORD_RESET_LINK_RESET = "🗑 PASSWORD_RESET_LINK_RESET";

export const PASSWORD_RESET_START = "🎬 PASSWORD_RESET_START";
export const PASSWORD_RESET_ERROR = "🚫 PASSWORD_RESET_ERROR";
export const PASSWORD_RESET_SUCCESS = "✅ PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_RESET = "🗑 PASSWORD_RESET_RESET";

export const PASSWORD_CHANGE_START = "🎬 PASSWORD_CHANGE_START";
export const PASSWORD_CHANGE_SUCCESS = "✅ PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CHANGE_RESET = "🗑 PASSWORD_CHANGE_RESET";

export const USER_ACCOUNT_DELETE_START = "🎬 USER_ACCOUNT_DELETE_START";
export const USER_ACCOUNT_DELETE_RESET = "🗑 USER_ACCOUNT_DELETE_RESET";
