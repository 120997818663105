const paths = {
	home: '/',
	login: '/login',
	register: '/register',
	registerConfirm: '/register-confirm',
	passwordReset: '/password-reset',
	passwordResetLink: '/password-reset-link',
	passwordResetVerify: '/password-reset-verify',
	passwordResetLinkConfirm: '/password-reset-link-confirm',
	verifyEmail: '/verify-email/:id',
	myAccount: '/my-account',
	tag: '/tag/:id',
	tagPreview: '/id/:id',

	dynamic: {
		tag: id => `/tag/${id}`,
		tagPreview: id => `/id/${id}`,
		verifyEmail: id => `/verify-email/${id}`,
	},
};

export default paths;
