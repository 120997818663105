import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import {
	LOGIN_ERROR,
	LOGIN_START,
	LOGIN_SUCCESS,
	REGISTER_ERROR,
	REGISTER_START,
	REGISTER_SUCCESS,
	PASSWORD_RESET_LINK_ERROR,
	PASSWORD_RESET_LINK_RESET,
	PASSWORD_RESET_LINK_START,
	VERIFY_EMAIL_ERROR,
	VERIFY_EMAIL_RESET,
	VERIFY_EMAIL_START,
	VERIFY_EMAIL_SUCCESS,
	PASSWORD_RESET_START,
	PASSWORD_RESET_SUCCESS,
	PASSWORD_RESET_ERROR,
	PASSWORD_RESET_RESET,
	PASSWORD_CHANGE_START,
	PASSWORD_CHANGE_RESET,
	PASSWORD_CHANGE_SUCCESS,
	USER_ACCOUNT_DELETE_START,
	USER_ACCOUNT_DELETE_RESET,
} from "./types";
import { RESET_STATE } from "../../types";
import { LOCAL_STORAGE_USER_TOKEN } from "../../../constants/localStorage";
import router from '../../../router';
import paths from "../../../constants/paths";
import service from "../../../../service";
import {
	API_LOGIN,
	API_LOGOUT,
	API_PASSWORD_CHANGE,
	API_PASSWORD_RESET,
	API_PASSWORD_RESET_LINK,
	API_REGISTER,
	API_USER_ACCOUNT_DELETE,
	API_VERIFY_EMAIL
} from "../../../../endpoints";

const state = {
	login: {
		isLoading: false,
		error: null,
		token: localStorage.getItem(LOCAL_STORAGE_USER_TOKEN) || null,
	},
	register: {
		isLoading: false,
		error: null,
	},
	verifyEmail: {
		isLoading: false,
		error: false,
		success: false,
	},
	passwordResetLink: {
		isLoading: false,
		error: false,
	},
	passwordReset: {
		isLoading: false,
		error: false,
		success: false,
	},
	passwordChange: {
		isLoading: false,
		success: false,
	},
	userAccountDelete: {
		isLoading: false,
	},
};

const getters = {
	isAuthenticated: state => !!state.login.token,
};

const mutations = {
	// REGISTER
	[REGISTER_START]: state => {
		state.register.error = null;
		state.register.isLoading = true;
	},
	[REGISTER_SUCCESS]: state => {
		state.register.isLoading = false;
		state.register.error = null;
	},
	[REGISTER_ERROR]: (state, error) => {
		state.register.isLoading = false;
		state.register.error = error;
	},
	// END REGISTER

	// LOGIN
	[LOGIN_START]: state => {
		state.login.token = null;
		state.login.error = null;
		state.login.isLoading = true;
	},
	[LOGIN_SUCCESS]: (state, token) => {
		state.login.error = null;
		state.login.isLoading = false;
		state.login.token = token;
	},
	[LOGIN_ERROR]: (state, error) => {
		state.login.token = null;
		state.login.isLoading = false;
		state.login.error = error;
	},
	// END LOGIN

	// VERIFY EMAIL
	[VERIFY_EMAIL_START]: state => {
		state.verifyEmail.success = false;
		state.verifyEmail.error = false;
		state.verifyEmail.isLoading = true;
	},
	[VERIFY_EMAIL_SUCCESS]: state => {
		state.verifyEmail.error = false;
		state.verifyEmail.isLoading = false;
		state.verifyEmail.success = true;
	},
	[VERIFY_EMAIL_ERROR]: state => {
		state.verifyEmail.isLoading = false;
		state.verifyEmail.success = false;
		state.verifyEmail.error = true;
	},
	[VERIFY_EMAIL_RESET]: state => {
		state.verifyEmail.isLoading = false;
		state.verifyEmail.success = false;
		state.verifyEmail.error = false;
	},
	// END VERIFY EMAIL

	// RESET PASSWORD EMAIL LINK
	[PASSWORD_RESET_LINK_START]: state => {
		state.passwordResetLink.error = false;
		state.passwordResetLink.isLoading = true;
	},
	[PASSWORD_RESET_LINK_ERROR]: state => {
		state.passwordResetLink.isLoading = false;
		state.passwordResetLink.error = true;
	},
	[PASSWORD_RESET_LINK_RESET]: state => {
		state.passwordResetLink.isLoading = false;
		state.passwordResetLink.error = false;
	},
	// END RESET PASSWORD EMAIL LINK

	// PASSWORD RESET
	[PASSWORD_RESET_START]: state => {
		state.passwordReset.success = false;
		state.passwordReset.error = false;
		state.passwordReset.isLoading = true;
	},
	[PASSWORD_RESET_SUCCESS]: state => {
		state.passwordReset.error = false;
		state.passwordReset.isLoading = false;
		state.passwordReset.success = true;
	},
	[PASSWORD_RESET_ERROR]: state => {
		state.passwordReset.isLoading = false;
		state.passwordReset.success = false;
		state.passwordReset.error = true;
	},
	[PASSWORD_RESET_RESET]: state => {
		state.passwordReset.isLoading = false;
		state.passwordReset.success = false;
		state.passwordReset.error = false;
	},
	// END PASSWORD RESET

	// PASSWORD CHANGE
	[PASSWORD_CHANGE_START]: state => {
		state.passwordChange.isLoading = true;
		state.passwordChange.success = false;
	},
	[PASSWORD_CHANGE_SUCCESS]: state => {
		state.passwordChange.success = true;
		state.passwordChange.isLoading = false;
	},
	[PASSWORD_CHANGE_RESET]: state => {
		state.passwordChange.success = false;
		state.passwordChange.isLoading = false;
	},
	// END PASSWORD CHANGE

	// USER DELETE ACCOUNT
	[USER_ACCOUNT_DELETE_START]: state => {
		state.userAccountDelete.isLoading = true;
	},
	[USER_ACCOUNT_DELETE_RESET]: state => {
		state.userAccountDelete.isLoading = false;
	},
	// END USER DELETE ACCOUNT
};

const actions = {
	// REGISTER
	async requestRegister({ commit, dispatch }, inputData) {
		commit(REGISTER_START);

		try {
			await service.post(API_REGISTER, inputData);
			await commit(REGISTER_SUCCESS);
			await router.push( paths.registerConfirm );
			dispatch(ADD_TOAST_MESSAGE, { text: 'Confirm your email address', type: 'success'});
		} catch (e) {
			console.log( e, 'requestRegister' );
			const errorMsg = e?.response?.data?.errors ? Object.values(e.response.data.errors)[0][0] : 'Something went wrong';
			commit(REGISTER_ERROR);
			dispatch(ADD_TOAST_MESSAGE, { text: errorMsg, type: 'danger'});
		}
	},

	// LOGIN
	async requestLogin({ commit, dispatch, rootState }, inputData) {
		commit(LOGIN_START);

		const error = 'Email or password is invalid';

		try {
			const { data: loginData } = await service.post(API_LOGIN, inputData);
			const token = loginData?.access_token;

			if (!token) {
				throw new Error('Token is missing');
			}

			await localStorage.setItem(LOCAL_STORAGE_USER_TOKEN, token);
			await dispatch( 'requestGetUserDetails', { root: true } );
			await commit(LOGIN_SUCCESS, token);
			await router.push( paths.home );
			dispatch(ADD_TOAST_MESSAGE, { text: `Hi ${rootState?.user?.data?.name ? rootState.user.data.name : ''}`, type: 'success'});
		} catch (e) {
			console.log( e, 'requestLogin' );
			localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN);
			commit(LOGIN_ERROR, error);
			dispatch(ADD_TOAST_MESSAGE, { text: error, type: 'danger'});
		}
	},

	// LOGOUT
	async requestLogout({ commit }, option ) {
		try {
			await service.get(API_LOGOUT);
		} catch (e) {
			console.log( e, 'requestLogout' );
		} finally {
			await localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN);
			await commit(RESET_STATE);
			await router.push( option.redirectPath || paths.login );
		}
	},

	// VERIFY EMAIL
	async requestVerifyEmail({ commit, dispatch }, query) {
		commit(VERIFY_EMAIL_START);
		try {
			await service.get(`${API_VERIFY_EMAIL}/${query}`);
			commit(VERIFY_EMAIL_SUCCESS);
			dispatch(ADD_TOAST_MESSAGE, { text: 'You have been successfully registered', type: 'success'});
		} catch (e) {
			console.log( e, 'requestVerifyEmail' );
			commit(VERIFY_EMAIL_ERROR);
			dispatch(ADD_TOAST_MESSAGE, { text: 'Something went wrong', type: 'danger'});
		}
	},
	// RESET VERIFY EMAIL
	async requestVerifyEmailReset({ commit }) {
		commit(VERIFY_EMAIL_RESET);
	},

	// PASSWORD RESET LINK
	async requestPasswordResetLink({ commit, dispatch }, dataToSend) {
		commit(PASSWORD_RESET_LINK_START);
		try {
			await service.post(API_PASSWORD_RESET_LINK, dataToSend);
			await router.push(paths.passwordResetLinkConfirm);
			dispatch(ADD_TOAST_MESSAGE, { text: 'Check your email for a password reset link', type: 'success'});
		} catch (e) {
			console.log( e, 'requestPasswordResetLink' );
			commit(PASSWORD_RESET_LINK_ERROR);
			dispatch(ADD_TOAST_MESSAGE, { text: 'Something went wrong', type: 'danger'});
		}
	},
	// RESET PASSWORD RESET LINK
	async requestPasswordResetLinkReset({ commit }) {
		commit(PASSWORD_RESET_LINK_RESET);
	},

	// PASSWORD RESET
	async requestPasswordReset({ commit, dispatch }, dataToSend) {
		commit(PASSWORD_RESET_START);
		try {
			await service.post(API_PASSWORD_RESET, dataToSend);
			commit(PASSWORD_RESET_SUCCESS);
			dispatch(ADD_TOAST_MESSAGE, { text: 'Password has been changed successfully!', type: 'success'});
		} catch (e) {
			console.log( e, 'requestPasswordReset' );
			const errorMsg = e?.response?.data?.errors ? Object.values(e.response.data.errors)[0][0] : 'Something went wrong';
			commit(PASSWORD_RESET_ERROR);
			dispatch(ADD_TOAST_MESSAGE, { text: errorMsg, type: 'danger'});
		}
	},
	// RESET PASSWORD RESET
	async requestPasswordResetReset({ commit }) {
		commit(PASSWORD_RESET_RESET);
	},

	// PASSWORD Change
	async requestPasswordChange({ commit, dispatch }, dataToSend) {
		commit(PASSWORD_CHANGE_START);
		try {
			await service.post(API_PASSWORD_CHANGE, dataToSend);
			commit(PASSWORD_CHANGE_SUCCESS);
			dispatch(ADD_TOAST_MESSAGE, { text: 'Password has been changed successfully!', type: 'success'});
		} catch (e) {
			console.log( e, 'requestPasswordChange' );
			commit(PASSWORD_CHANGE_RESET);
			const errorMsg = e?.response?.data?.msg || 'Something went wrong';
			dispatch(ADD_TOAST_MESSAGE, { text: errorMsg || 'Something went wrong', type: 'danger'});
		}
	},
	// RESET PASSWORD RESET
	async requestPasswordChangeReset({ commit }) {
		commit(PASSWORD_CHANGE_RESET);
	},

	// USER ACCOUNT DELETE
	async requestUserAccountDelete({ commit, dispatch }) {
		commit(USER_ACCOUNT_DELETE_START);
		try {
			await service.get(API_USER_ACCOUNT_DELETE);
			await localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN);
			await commit(RESET_STATE);
			await router.push( paths.register );
			dispatch(ADD_TOAST_MESSAGE, { text: 'Account has been deleted successfully!', type: 'success'});
		} catch (e) {
			console.log( e, 'requestUserAccountDelete' );
			dispatch(ADD_TOAST_MESSAGE, { text: 'Something went wrong. Please try later.', type: 'danger'});
		} finally {
			commit(USER_ACCOUNT_DELETE_RESET)
		}
	},

};

export default {
	state,
	getters,
	mutations,
	actions,
}
